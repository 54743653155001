import React from "react"
import LayoutRegular from "../components/layoutRegular"
import StyledFullBackground from "../components/backgroundImage"
import SEO from "../components/seo"
import { Link } from "gatsby"

const pageSEO = {title: "About", description: "Benjamin Engeli is one of the most versatile Swiss musicians of his generation. His career as a soloist, chamber musician and teacher has taken him to most European countries as well as to Australia, India, North and South America."}

export default function About() {
  return (
    <StyledFullBackground imgName="alex">
        <LayoutRegular>    
        <SEO title={pageSEO.title} description={pageSEO.description} />
        <div className="content">
        
        <div className="flex-row-center">
          <h1>Biography</h1>
          <div className="language-button slide-in mobile-only"><Link to="/about-de">auf Deutsch</Link></div>
        </div>
        
        <div className="divider"></div>
        <p className="text">
          Each week Benjamin Engeli commutes around 1000 kilometers by train for his many concerts, his teaching job at Zürcher Hochschule der Künste, his various Jury engagements and his commitment for his family. His versatility is also reflected in his impressive piano <Link to="/repertoire">repertoire</Link>. As a soloist he appears with renowned orchestras such as the Bavarian Radio Symphony Orchestra, the Tchaikovsky Symphony Orchestra Moscow and the Tonhalle Orchestra Zurich.
          <br/><br/>
          Benjamin is also a gifted chamber musician: as a member of the <a href="https://de.wikipedia.org/wiki/Tecchler_Trio" target="_blank" rel="noreferrer">Tecchler Trio</a> he won the 2007 ARD Music Competition, and is now appearing with the <a href="https://www.ensemblekandinsky.com/" target="_blank" rel="noreferrer">Ensemble Kandinsky</a> and other musical partners in venues including the Concertgebouw Amsterdam, Konzerthaus Berlin, Wigmore Hall London, Teatro Municipal do Rio de Janeiro, Shanghai Oriental Art Center and the Vienna Musikverein. As member of the <a href="http://www.gershwinpianoquartet.com/" target="_blank" rel="noreferrer">Gershwin Piano Quartet</a> he likes to cross musical borders and is also active as an arranger of music by many different composers, ranging from Johann Sebastian Bach to Michael Jackson. Engeli hails from a musical family and started his musical education on various instruments at an early age. He studied horn at first before eventually concentrating on his principal instrument, studying with Adrian Oetiker at the Musik-Akademie Basel. He undertook further studies with Homero Francesch, Lazar Berman, Andrzej Jasinski, Maurizio Pollini and Andràs Schiff.

        {/* Each week Benjamin Engeli commutes around 1000 kilometers by train for his many concerts, his teaching job at the <a href="https://vlk.ac.at/das-konservatorium/lehrk%C3%B6rper/dozenten-detail/engeli_benjamin" target="_blank" rel="noreferrer">Vorarlberger Landeskonservatorium in Feldkirch</a>, his various Jury engagements and his commitment as a father of a three-year-old daughter. 
        His versatility is also reflected in his impressive piano <Link to="/repertoire">repertoire</Link>. As a soloist he appears with renowned orchestras such as the Bavarian Radio Symphony Orchestra, the Tchaikovsky Symphony Orchestra Moscow and the Tonhalle Orchestra Zurich. 
        <br/><br/>Benjamin is also a gifted chamber musician: as a member of the <a href="https://de.wikipedia.org/wiki/Tecchler_Trio" target="_blank" rel="noreferrer">Tecchler Trio</a> he won the 2007 ARD Music Competition, and is now appearing with the <a href="https://www.ensemblekandinsky.com/" target="_blank" rel="noreferrer">Ensemble Kandinsky</a> and other musical partners in venues including the Concertgebouw Amsterdam, Konzerthaus Berlin, Wigmore Hall London, Theatro Municipal do Rio de Janeiro, Shanghai Oriental Art Center and the Vienna Musikverein. As member of the <a href="http://www.gershwinpianoquartet.com/" target="_blank" rel="noreferrer">Gershwin Piano Quartet</a> he likes to cross musical borders and is also active as an arranger of music by many different composers, ranging from Johann Sebastian Bach to Michael Jackson. 
        Engeli hails from a musical family and started his musical education on various instruments at an early age. He studied horn at first before eventually concentrating on his principal instrument, studying with Adrian Oetiker at the Musik-Akademie Basel. He undertook further studies with Homero Francesch, Lazar Berman, Andrzej Jasinski, Maurizio Pollini and Andràs Schiff. */}

            {/*
            <a href="" target="_blank" rel="noreferrer"></a>
            Benjamin Engeli is one of the most versatile Swiss musicians of his generation. His career as a soloist, chamber musician and teacher has taken him to most European countries as well as to Australia, India, North and South America. As a member of the Tecchler Trio, he was the 1st Prize winner at the International ARD Music Competition in Munich in 2007, and has since then performed in venues such as the Concertgebouw Amsterdam, Konzerthaus Berlin, Wigmore Hall London, Tchaikovsky Conservatory Moscow, Oriental Arts Center Shanghai, Konzerthaus Vienna or Tonhalle Zurich. 
            <br /><br />He is now member of the Ensemble Kandinsky and also one out of the 4 pianists of the Gershwin Piano Quartet, with whom he conducted concert tours to Brazil, China and the Middle East.
            Benjamin comes from a family of musicians and started his musical education on various instruments at an early age. Only when he was fifteen did he decide to take his first regular piano lessons, studying with Adrian Oetiker with whom he continued to work for 7 years. He later also studied with Homero Francesch, Lazar Berman, Andrzej Jasinski, Maurizio Pollini and András Schiff.
            Since many years he is also teaching up-and-coming musicians. For 8 years he held a teaching position for chamber music at Musikhochschule Basel (Switzerland) and serves now as Professor for Piano at Landeskonservatorium Feldkirch (Austria).¨
            */}
        </p>
        </div>
        </LayoutRegular>
    </StyledFullBackground>
  )
}
